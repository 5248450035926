import React from 'react'
import {Container, Row, Col} from 'reactstrap';

import Layout from '../components/layout';
import SEO from '../components/seo';
import {Pricing} from "../components/pricing";
import IntelligentePatientenkarteiImage from "../components/IntelligentePatientenkarteiImage";
import PatientenkarteiImage from "../components/PatientenkarteiImage";
import {withPrefix} from "gatsby-link";

const Patientenkartei = (props) => (
    <Layout>
        <SEO googleTitle="Medoboard - die Patientenkartei auf einen Klick"
             googleDescription="Während dem Patientengespräch sind umfangreiche Informationen entscheidend."
             fbUrl="https://medoboard.com/patientenkartei"
             fbImage={withPrefix('medoboard-fb.png')}
             fbTitle="Medoboard - die Patientenkartei auf einen Klick"
             fbDescription="Während dem Patientengespräch sind umfangreiche Informationen entscheidend."
             keywords={[`medoboard`, `Ordinationssoftware`, `intelligent`, `einfach`, `Patientenakte`, `Arztsoftware`, `Patientenkartei`, `ELGA`]}/>
        <Container>
            <Row>
                <Col md="12">
                    <h2 className="featurette-heading">Patientenkartei</h2>
                    <h4 className="text-muted">In Millisekunden zur Patientenkartei</h4>
                    <p className="lead">
                        Während dem Patientengespräch sind umfangreiche Informationen entscheidend.
                    </p>
                </Col>
            </Row>
            <Row className="carousel">
                <Col md="6">
                    <PatientenkarteiImage/>
                </Col>
                <Col md="6" className="px-5">
                    <div className="featurette-content">
                        <div>
                            <h2 className="my-4">Patientenkartei</h2>
                            <p>
                                Die gesamte Patientengeschichte auf einen Blick.
                            </p>
                            <p>
                                Mit einem Klick.
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="carousel">
                <Col md="6" className="px-5">
                    <div className="featurette-content">
                        <div>
                            <h2 className="my-4">Intelligente Patientenkartei</h2>
                            <p>
                                Intelligente Textfelder helfen bei der Diagnose.
                            </p>
                            <p>
                                Direkt in der Patientenkartei.
                            </p>
                        </div>
                    </div>
                </Col>
                <Col md="6">
                    <IntelligentePatientenkarteiImage/>
                </Col>
            </Row>
            <hr className="featurette-divider"/>
            <Pricing/>
        </Container>
    </Layout>
);

export default Patientenkartei;